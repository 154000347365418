import { atom } from 'recoil';
import { PersistStorage, recoilPersist } from 'recoil-persist';
import { localStorageKeys } from '~/constants/localStorageKeys';
import { storageIsSupported } from '~/utils/storageIsSupported';

const dummyStorage: PersistStorage = {
  setItem: () => {},
  getItem: () => null,
};

const { persistAtom } = recoilPersist({
  key: localStorageKeys.businessType,
  storage: storageIsSupported(() => localStorage) ? localStorage : dummyStorage,
});

export const selfEmployedState = atom<boolean | null>({
  key: 'selfEmployed',
  default: null,
  effects_UNSTABLE: [persistAtom],
});
