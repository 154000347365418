export const storageIsSupported = (
  getStorage: () => {
    setItem: (key: string, val: string) => void;
    getItem: (key: string) => void;
    removeItem: (key: string) => void;
  }
) => {
  try {
    const key = 'localStorageIsSupported';
    getStorage().setItem(key, key);
    getStorage().getItem(key);
    getStorage().removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};
