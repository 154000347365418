import { useRecoilState } from 'recoil';
import { selfEmployedState } from '~/stores/businessType';

export const usePersistedBusinessType = () => {
  const [selfEmployed, setSelfEmployed] = useRecoilState(selfEmployedState);

  return {
    state: {
      selfEmployed,
    },
    actions: {
      saveBusinessType: (args: { selfEmployed: boolean }): void => {
        setSelfEmployed(args.selfEmployed);
      },
    },
  };
};
