import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';

export const calcFingerprintProId = async (): Promise<string | null> => {
  try {
    const fp = await FingerprintJS.load({
      apiKey: '2lIvY6ULBG8Jnez9oxLY',
      region: 'ap',
      endpoint: [
        'https://fp.taimee.co.jp',
        FingerprintJS.defaultEndpoint, // The default endpoint as fallback
      ],
      scriptUrlPattern: [
        'https://fp.taimee.co.jp/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
        FingerprintJS.defaultScriptUrlPattern, // Use the default endpoint as fallback
      ],
    });
    const { visitorId } = await fp.get();
    return visitorId;
  } catch {
    return null;
  }
};
